@import "./data.css";

.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	padding-bottom: 40px;
	width: 400px;
}

.work-image {
	height: 55px;
	width: 55px;
	border-radius: 50%;
	outline: 1px solid var(--tertiary-color);
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	color: var(--secondary-color);
}

.work-subtitle {
	font-size: 12px;
	color: var(--secondary-color);
	padding-left: 20px;
}

.work-duration {
	color: var(--tertiary-color);
}

@media (max-width: 420px) {
	.work > div:first-of-type{
		flex-direction: column;
	}
	.work > div:first-of-type > .work-duration{
		margin-left: 20px;
	}
	.school > div:first-of-type{
		flex-direction: column;
		width: 300px;
	}
	.school > div:first-of-type > .work-duration{
		margin-left: 20px;
		justify-content: start;
	}

}

@media (max-width: 640px) {
	.work {
		display: flex;
		padding-bottom: 40px;
		width: 300px;
	}
}