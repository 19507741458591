@import "./data.css";

.nav-container {
	margin: 0;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	top: 0;
	z-index: 999;
	position: relative;
}

.nav-background {
	width: 400px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: #fff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
    padding: 12px;
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	align-items: center;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}