@import "./data.css";

.footer {
	display: flex;
	padding-top: 25px;
	margin-top: 50px;
	padding-bottom: 25px;
	align-items: center;
	flex-direction: row;
	justify-content: space-evenly;
}

.footer-links {
	flex-basis: 50%;
}
.footer-credits {
	flex-basis: 30%;
}

.footer-links {
	/*margin-left: -40px;*/
}

.footer-nav-link-list {
	display: flex;
	list-style: none;
	justify-content: space-between;
	align-items: center;
}

.footer-nav-link-item {
	font-weight: bold;
	font-size: 80%;
}

.footer-nav-link-list a {
	text-decoration: none;
	color: var(--secondary-color);
}

.footer-nav-link-list a:hover {
	color: var(--link-color);
}

.footer-credits-text {
	justify-content: flex-end;
	color: var(--tertiary-color);
	font-size: 14px;
	text-align: right;
}


@media (max-width: 600px) {
	.footer-credits {
		flex-basis: 100%;
	}

	.footer-credits-text {
		color: var(--tertiary-color);
		font-size: 16px;
		text-align: center;
	}
}