.heading-default {
    margin: 0 auto 70px;
    letter-spacing: 1px;
    font-size: 38px;
    font-weight: 400;
    font-family: "Abril Fatface",cursive;
    text-align: center;
    position: relative;
    display: table
}

.heading-default:after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    margin: 5px 0 0;
    background-color: #ffcf19;
    transition: width 1.5s ease-in-out
}

.heading-default.is-on:after {
    width: 100%
}

.heading-default__small {
    margin: 0 0 20px;
    padding: 3px 0 3px 20px;
    font-size: 16px;
    border-left: 3px solid;
    font-weight: bold
}

.heading-default__small i {
    margin: 0 10px 0 0;
    width: 30px;
    height: 25px;
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: #fff;
    color: #000
}



@keyframes blur-text {
    0% {
        filter: blur(0px)
    }

    100% {
        filter: blur(4px)
    }
}






.my-resume .my-resume__inner {
    margin: -1rem 0 -15px
}

.my-resume .my-resume__inner .my-resume__skill--item {
    margin: 30px 0;
    display: flex
}

.my-resume .my-resume__inner .my-resume__skill--item label {
    width: 200px;
    display: inline-flex;
    align-items: center
}

.my-resume .my-resume__inner .my-resume__skill--item label:after {
    content: "";
    display: inline-flex;
    width: auto;
    height: 1px;
    background-color: #000;
    flex: 1;
    margin: 0 10px 0 15px
}

.my-resume .my-resume__inner .my-resume__skill--item div span {
    margin: 0 3px;
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000
}

.my-resume .my-resume__inner .my-resume__skill--item div span.active {
    background-color: #000
}

.my-resume .my-resume__inner .my-resume__skill--item.custom {
    opacity: 0;
    transform: translateY(30px);
    transition: all 1.5s ease
}

.my-resume .my-resume__inner .my-resume__skill--item.is-on {
    transform: translateY(0px);
    opacity: 1
}

.my-resume .my-resume__inner .education__list .education__item {
    margin: 20px 0 30px
}

.my-resume .my-resume__inner .education__list .education__item .education__date {
    margin: 0 0 5px;
    font-weight: 500;
    font-size: 16px
}

.my-resume .my-resume__inner .education__list .education__item .education__name {
    margin: 0 0 5px;
    font-size: 16px
}

.my-resume .my-resume__inner .education__list .education__item .education__subname {
    font-size: 16px
}

.my-resume .my-resume__inner .experience__list .experience__item {
    margin: 20px 0 30px
}

.my-resume .my-resume__inner .experience__list .experience__item .experience__date {
    margin: 0 0 5px;
    font-weight: 500;
    font-size: 16px
}

.my-resume .my-resume__inner .experience__list .experience__item .experience__company {
    margin: 0 0 5px;
    font-size: 16px
}

.my-resume .my-resume__inner .experience__list .experience__item .experience__position {
    margin: 0 0 5px;
    font-size: 16px
}

.my-resume .my-resume__inner .experience__list .experience__item .experience__description {
    font-size: 16px
}

.my-resume .my-resume__inner .awards__list .awards__item {
    margin: 20px 0 30px
}

.my-resume .my-resume__inner .awards__list .awards__item .awards__date {
    margin: 0 0 5px;
    font-weight: 500;
    font-size: 16px
}

.my-resume .my-resume__inner .awards__list .awards__item .awards__name {
    margin: 0 0 5px;
    font-size: 16px
}

.my-resume .my-resume__inner .awards__list .awards__item .awards__description {
    font-size: 16px
}





.highlight {
    margin: 0 0 0 1px;
    padding: 15px 0;
    border-left: 1px solid #666
}

.highlight .highlight__item {
    padding: 0 0 0 32px;
    position: relative
}

.highlight .highlight__item:before {
    content: "";
    width: 9px;
    height: 9px;
    display: inline-block;
    position: absolute;
    left: -5px;
    top: 5px;
    border: 1px solid #000;
    background-color: #fff;
    opacity: 1;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: background-color .5s ease
}

/* styles.css */
.st0 {
    fill: #E21D38;
  }
  
  .st1 {
    fill: #FFFFFF;
  }
  
  .st2 {
    font-family: 'ArialMT';
  }
  
  .st3 {
    font-size: 26.4264px;
  }
  
  .st4 {
    fill: none;
    stroke: #E21D38;
    stroke-width: 3.2937;
    stroke-miterlimit: 120.1201;
  }
  