:root {
	/* ------- colors ------- */
	--primary-color: #27272a;
	--secondary-color: #65656d;
	--tertiary-color: #acacb4;
	--quaternary-color: #e4e4e7;
	--link-color: #14b8a6;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}

.tooltip {
	position: relative;
	display: inline-block;
  }
  
  .tooltip .tooltiptext {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
  
	/* Position the tooltip */
	position: absolute;
	z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
	visibility: visible;
  }

  .link:hover div {
	background-color: var(--link-color);
	border-radius: 50%;
	transition: color 0.3s ease-in-out;
  }